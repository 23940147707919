import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const useFilmsMaterials = ({ options }) => {
  const filmsMaterials = useQuery(
    ['filmsMaterials'],
    async () => {
      const { data } = await api.lectures.getRandomVideoMaterials(
        options?.count,
      );
      return data;
    },
    { ...defaultOptions, ...options, select: ({ data }) => data },
  );

  onServerPrefetch(async () => {
    await filmsMaterials.suspense();
  });

  return {
    filmsMaterials,
  };
};
